<template>
  <div class="inputBox">
      <input
        v-bind="$attrs"
        v-bind:value="value"
        v-on="inputListeners"
      >
    <label>{{label}}</label>
      <span class="errMsg" v-if="errMsg.length > 0">{{errMsg}}</span>

  </div>
</template>

<script>
  export default {
    name: "base-input",
    inheritAttrs: false,
    props: {
      label: {
        type: String,
        default: '',
      },
      value: [Number,String],
      errMsg: {
        type: String,
        default: '',
      },
    },
    computed: {
      inputListeners: function () {
        var vm = this;
        // `Object.assign` объединяет объекты вместе, чтобы получить новый объект
        return Object.assign({},
          // Мы добавляем все слушатели из родителя
          this.$listeners,
          // Затем мы можем добавить собственные слушатели или
          // перезаписать поведение некоторых существующих.
          {
            // Это обеспечит, что будет работать v-model на компоненте
            input: function (event) {
              vm.$emit('input', event.target.value)
            }
          }
        )
      }
    },

  }
</script>

<style scoped>
  .inputBoxWrap{background: #F6F8F7;padding: 3vw 3vw 2vw;border-radius: 1px;margin: 1vw 0;}
  .inputBoxWrap label{display: block;color:#869A95;font-size:  4vw;}
  .inputBoxWrap input{display: block;color:#222721;font-size: 5vw;padding: 1.6vw 0
  ;background: transparent;border: 0;width: 100%;}
  .inputBoxWrap p{display: block;}

  @media screen and (min-width: 760px) {
    .inputBoxWrap{margin:0 0 0.6vw;padding: 1.5vw 1.1vw 1vw;}
    .inputBoxWrap label{font-size: 1.1vw;}
    .inputBoxWrap input{font-size: 1.3vw;padding: 0.2vw 0;}
    .inputBoxWrap p{}
  }
  @media screen and (min-width: 1140px) {
    .inputBoxWrap{padding: 17px 13px 11px;margin-bottom: 7px;margin: 5px 0;}
    .inputBoxWrap label{font-size:  12px;}
    .inputBoxWrap input{font-size: 14px;padding: 2px 0;}
    .inputBoxWrap p{}
  }
</style>
